import React from "react";
import BackToTopButton from "../components/BackToTopButton";
import useSingleFetch from "../hooks/useSingleFetch";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  const { data } = useSingleFetch("users", "fkxkksUoTaSGhc9P9JQRpHNVRn22");
  const { displayName, userImg } = data;

  return (
    <>
      <div className="w-full md:w-[80%] mx-auto md:p-[3rem] mt-5 md:mt-0 md:bg-white min-h-screen md:shadow relative">
        <h2 className="font-semibold border-b text-xl">About Waypost</h2>
        <div className="flex items-center gap-2 flex-wrap mt-5">
          <img
            src={userImg}
            alt={displayName}
            className="cursor-pointer h-10 w-10 object-cover rounded-full"
            onClick={() => navigate("/")}
          />
          <div className="flex flex-col text-xs">
            <span className="">{data.displayName}</span>
            <span className="">Creator of Waypost</span>
          </div>
        </div>

        <p className="my-5">Welcome to Waypost!</p>
        <p>
          This is an experimental web application that started with a question
          to myself of how should I document my work experiences and job related
          skills to easily share with others.
        </p>
        <br />
        <p>
          Although this app has potential for many use cases, the main idea is
          for employers to come to Waypost to gain insight on the type of work
          I've been doing because a resume is very limited.
        </p>
        <br />
        <p>
          For now, the experimental phase is just me posting on here, so that I
          could discover improvements for the overall web application.
        </p>
      </div>

      <BackToTopButton />
    </>
  );
};

export default About;
